<template>
	<modal class="NoxModalTelegramBot" name="NoxModalTelegramBot" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addTBARAnswer'">Добавление ответа на вопрос</span>
					<span v-else-if="noxType == 'editTBARAnswer'">Редактирование ответа на вопрос</span>
					<span v-else-if="noxType == 'deleteTBARAnswer'">Удаление ответа на вопрос</span>
					<span v-else-if="noxType == 'deleteTBARQuestions'">Удаление всех вопросов</span>
					<span v-else-if="noxType == 'addTBARTemplate'">Добавление шаблона вопроса</span>
					<span v-else-if="noxType == 'editTBARTemplate'">Редактирование шаблона вопроса</span>
					<span v-else-if="noxType == 'deleteTBARTemplate'">Удаление шаблона вопроса</span>
					<span v-else-if="noxType == 'addTBMCCommand'">Добавление команды</span>
					<span v-else-if="noxType == 'editTBMCCommand'">Редактирование команды</span>
					<span v-else-if="noxType == 'deleteTBMCCommand'">Удаление команды</span>
					<span v-else-if="noxType == 'addTBMCBlock'">Добавление блока команды</span>
					<span v-else-if="noxType == 'editTBMCBlock'">Редактирование блока команды</span>
					<span v-else-if="noxType == 'swapUpTBMCBlock'">Перемещение блока команды</span>
					<span v-else-if="noxType == 'swapDownTBMCBlock'">Перемещение блока команды</span>
					<span v-else-if="noxType == 'deleteTBMCBlock'">Удаление блока команды</span>
					<span v-else-if="noxType == 'addTBMCKeyboard'">Добавление клавиатуры</span>
					<span v-else-if="noxType == 'editTBMCKeyboard'">Редактирование клавиатуры</span>
					<span v-else-if="noxType == 'saveTBMCKeyboard'">Сохранение изменений клавиатуры</span>
					<span v-else-if="noxType == 'addTBMCKeyboardRow'">Добавление строки клавиатуры</span>
					<span v-else-if="noxType == 'deleteTBMCKeyboardRow'">Удаление строки клавиатуры</span>
					<span v-else-if="noxType == 'addTBMCKeyboardCell'">Добавление кнопки клавиатуры</span>
					<span v-else-if="noxType == 'editTBMCKeyboardCell'">Редактирование кнопки клавиатуры</span>
					<span v-else-if="noxType == 'deleteTBMCKeyboardCell'">Удаление кнопки клавиатуры</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div class="nox_modal_info_tb">
						<div v-if="['addTBARAnswer', 'editTBARAnswer'].includes(noxType)">
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Текст ответа:</div>
								<div class="nox_modal_info_row_value">
									<textarea class="nox_textarea small" v-model.trim="noxTBARAnswerText" placeholder="укажите текст ответа на вопрос"></textarea>
									<div v-html="noxAlertTBARAnswerText"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'deleteTBARAnswer'">Вы действительно хотите удалить ответ на вопрос?</div>
						<div v-else-if="noxType == 'deleteTBARQuestions'">Вы действительно хотите удалить все вопросы?</div>
						<div v-else-if="['addTBARTemplate', 'editTBARTemplate'].includes(noxType)">
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Ответ на вопрос:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxTBARTemplateAId" placeholder="выберите ответ на вопрос" :options="$parent.noxTBARAnswersOptions"></v-select>
									<div v-html="noxAlertTBARTemplateAId"></div>
								</div>
							</div>
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Шаблон вопроса:</div>
								<div class="nox_modal_info_row_value">
									<textarea class="nox_textarea small" v-model.trim="noxTBARTemplateText" placeholder="укажите шаблон вопроса"></textarea>
									<div v-html="noxAlertTBARTemplateText"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'deleteTBARTemplate'">Вы действительно хотите удалить шаблон вопроса?</div>
						<div v-else-if="['addTBMCCommand', 'editTBMCCommand'].includes(noxType)">
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Название команды:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxTBMCCommandName" placeholder="укажите название команды" autocomplete="off">
									<div v-html="noxAlertTBMCCommandName"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'deleteTBMCCommand'">Вы действительно хотите удалить команду?</div>
						<div v-else-if="['addTBMCBlock', 'editTBMCBlock'].includes(noxType)">
							<div class="nox_modal_info_row" :class="{ last: !noxTBMCBlockType }">
								<div class="nox_modal_info_row_label">Тип блока:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxTBMCBlockType" placeholder="выберите тип блока" :options="$parent.noxTBMCBlockTypes"></v-select>
									<div v-html="noxAlertTBMCBlockType"></div>
								</div>
							</div>
							<div class="nox_modal_info_row" v-if="noxTBMCBlockType && noxTBMCBlockType.code == 3">
								<div class="nox_modal_info_row_label">Вызов команды:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxTBMCBlockNextCId" placeholder="выберите вызываемую команду" :options="$parent.noxTBMCCommandsOptions"></v-select>
									<div v-html="noxAlertTBMCBlockNextCId"></div>
								</div>
							</div>
							<div class="nox_modal_info_row" v-if="noxTBMCBlockType && noxTBMCBlockType.code == 3">
								<div class="nox_modal_info_row_label">Получатели ответа:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxTBMCBlockRecipients" placeholder="укажите telegram id получателей" autocomplete="off">
									<div v-html="noxAlertTBMCBlockRecipients"></div>
								</div>
							</div>
							<div class="nox_modal_info_row" v-if="noxTBMCBlockType">
								<div class="nox_modal_info_row_label">Текст блока (RU):</div>
								<div class="nox_modal_info_row_value">
									<textarea class="nox_textarea small" v-model.trim="noxTBMCBlockTextRU" placeholder="укажите текст блока (ru)"></textarea>
									<div v-html="noxAlertTBMCBlockTextRU"></div>
								</div>
							</div>
							<div class="nox_modal_info_row" :class="{ last: noxTBMCBlockType.code !== 0 }" v-if="noxTBMCBlockType">
								<div class="nox_modal_info_row_label">Текст блока (EN):</div>
								<div class="nox_modal_info_row_value">
									<textarea class="nox_textarea small" v-model.trim="noxTBMCBlockTextEN" placeholder="укажите текст блока (en)"></textarea>
									<div v-html="noxAlertTBMCBlockTextEN"></div>
								</div>
							</div>
							<div class="nox_modal_info_row" :class="{ last: noxTBMCBlockType.code == 0 }" v-if="noxTBMCBlockType && noxTBMCBlockType.code == 0">
								<div class="nox_modal_info_row_label">Предпросмотр ссылок:</div>
								<div class="nox_modal_info_row_value">
									<toggle-button class="nox_toggle_button" :value="toggleValue(noxTBMCBlockIsPreview)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(2, $event)"></toggle-button>
									<div v-html="noxAlertTBMCBlockIsPreview"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'swapUpTBMCBlock'">Вы действительно хотите переместить блок команды выше?</div>
						<div v-else-if="noxType == 'swapDownTBMCBlock'">Вы действительно хотите переместить блок команды ниже?</div>
						<div v-else-if="noxType == 'deleteTBMCBlock'">Вы действительно хотите удалить блок команды?</div>
						<div v-else-if="['addTBMCKeyboard', 'editTBMCKeyboard'].includes(noxType)">
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Тип клавиатуры:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxTBMCKeyboardType" placeholder="выберите тип клавиатуры" :options="noxTBMCKeyboardTypes"></v-select>
									<div v-html="noxAlertTBMCKeyboardType"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'saveTBMCKeyboard'">Вы действительно хотите сохранить изменения клавиатуры?</div>
						<div v-else-if="noxType == 'addTBMCKeyboardRow'">Вы действительно хотите добавить строку клавиатуры?</div>
						<div v-else-if="noxType == 'deleteTBMCKeyboardRow'">Вы действительно хотите удалить строку клавиатуры?</div>
						<div v-else-if="['addTBMCKeyboardCell', 'editTBMCKeyboardCell'].includes(noxType)">
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Статус кнопки:</div>
								<div class="nox_modal_info_row_value">
									<toggle-button class="nox_toggle_button" :value="toggleValue(noxTBMCKeyboardCellStatus)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange(1, $event)"></toggle-button>
									<div v-html="noxAlertTBMCKeyboardCellStatus"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Вызов команды:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxTBMCKeyboardNextCId" placeholder="выберите вызываемую команду" :options="$parent.noxTBMCCommandsOptions"></v-select>
									<div v-html="noxAlertTBMCKeyboardNextCId"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Название кнопки (RU):</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model="noxTBMCKeyboardCellNameRU" placeholder="укажите название кнопки (ru)" autocomplete="off">
									<div v-html="noxAlertTBMCKeyboardCellNameRU"></div>
								</div>
							</div>
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Название кнопки (EN):</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model="noxTBMCKeyboardCellNameEN" placeholder="укажите название кнопки (en)" autocomplete="off">
									<div v-html="noxAlertTBMCKeyboardCellNameEN"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'deleteTBMCKeyboardCell'">Вы действительно хотите удалить кнопку клавиатуры?</div>
					</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common red" @click="noxType = 'deleteTBMCKeyboardCell'" v-if="['editTBMCKeyboardCell'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="['addTBARAnswer', 'addTBARTemplate', 'addTBMCCommand', 'addTBMCBlock', 'addTBMCKeyboard', 'addTBMCKeyboardRow', 'addTBMCKeyboardCell'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="['editTBARAnswer', 'editTBARTemplate', 'editTBMCCommand', 'editTBMCBlock', 'editTBMCKeyboard', 'saveTBMCKeyboard', 'editTBMCKeyboardCell'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common blue" @click="axios" v-else-if="['swapUpTBMCBlock', 'swapDownTBMCBlock'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 144)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="['deleteTBARAnswer', 'deleteTBARQuestions', 'deleteTBARTemplate', 'deleteTBMCCommand', 'deleteTBMCBlock', 'deleteTBMCKeyboardRow', 'deleteTBMCKeyboardCell'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertTBARAnswerText: '',
			noxAlertTBARTemplateAId: '',
			noxAlertTBARTemplateText: '',
			noxAlertTBMCCommandName: '',
			noxAlertTBMCBlockType: '',
			noxAlertTBMCBlockTextRU: '',
			noxAlertTBMCBlockTextEN: '',
			noxAlertTBMCBlockNextCId: '',
			noxAlertTBMCBlockRecipients: '',
			noxAlertTBMCBlockIsPreview: '',
			noxAlertTBMCKeyboardType: '',
			noxAlertTBMCKeyboardNextCId: '',
			noxAlertTBMCKeyboardCellNameRU: '',
			noxAlertTBMCKeyboardCellNameEN: '',
			noxAlertTBMCKeyboardCellStatus: '',
			noxType: '',
			noxIndex: 0,
			noxTBARAnswerId: 0,
			noxTBARAnswerText: '',
			noxTBARTemplateId: 0,
			noxTBARTemplateAId: '',
			noxTBARTemplateText: '',
			noxTBMCCommandId: 0,
			noxTBMCCommandName: '',
			noxTBMCBlockId: 0,
			noxTBMCBlockType: '',
			noxTBMCBlockTextRU: '',
			noxTBMCBlockTextEN: '',
			noxTBMCBlockNextCId: '',
			noxTBMCBlockRecipients: '',
			noxTBMCBlockIsPreview: 0,
			noxTBMCKeyboardId: 0,
			noxTBMCKeyboardType: '',
			noxTBMCKeyboardTypes: [],
			noxTBMCKeyboardRowId: 0,
			noxTBMCKeyboardCellId: 0,
			noxTBMCKeyboardNextCId: '',
			noxTBMCKeyboardCellNameRU: '',
			noxTBMCKeyboardCellNameEN: '',
			noxTBMCKeyboardCellStatus: 0,
			noxTBTextLengths: [],
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxTBARAnswerId = event.params.aid;
				this.noxTBARAnswerText = '';
				this.noxTBARTemplateId = event.params.tid;
				this.noxTBARTemplateAId = '';
				this.noxTBARTemplateText = '';
				this.noxTBMCCommandId = event.params.cid;
				this.noxTBMCCommandName = '';
				this.noxTBMCBlockId = event.params.bid;
				this.noxTBMCBlockType = '';
				this.noxTBMCBlockTextRU = '';
				this.noxTBMCBlockTextEN = '';
				this.noxTBMCBlockNextCId = '';
				this.noxTBMCBlockRecipients = '';
				this.noxTBMCBlockIsPreview = 0;
				this.noxTBMCKeyboardId = event.params.kid;
				this.noxTBMCKeyboardType = '';
				this.noxTBMCKeyboardTypes = [
					{ code: 0, label: 'Обычная клавиатура' },
					{ code: 1, label: 'Инлайн клавиатура' }
				];
				this.noxTBMCKeyboardRowId = event.params.row_id;
				this.noxTBMCKeyboardCellId = event.params.cell_id;
				this.noxTBMCKeyboardNextCId = '';
				this.noxTBMCKeyboardCellNameRU = '';
				this.noxTBMCKeyboardCellNameEN = '';
				this.noxTBMCKeyboardCellStatus = 0;
				this.noxTBTextLengths = [4096,1024,200,4096];
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editTBARAnswer') {
					this.noxTBARAnswerText = this.$parent.noxTBARAnswers[this.noxIndex].text;
				}
				else if (this.noxType == 'editTBARTemplate') {

					this.noxTBARTemplateAId = this.$parent.noxTBARAnswersOptions[this.$parent.noxTBARAnswersOptions.findIndex(i => i.code == this.$parent.noxTBARTemplates[this.noxIndex].aid)];
					this.noxTBARTemplateText = this.$parent.noxTBARTemplates[this.noxIndex].text;
				}
				else if (this.noxType == 'editTBMCCommand') {

					this.noxData = this.$store.state.noxTBData[this.noxTBMCCommandId].data;
					this.noxTBMCCommandName = this.noxData.name;
				}
				else if (this.noxType == 'editTBMCBlock') {

					this.noxData = this.$store.state.noxTBData[this.noxTBMCCommandId].blocks['id' + this.noxTBMCBlockId].data;
					this.noxTBMCBlockType = this.$parent.noxTBMCBlockTypes[this.noxData.type];
					this.noxTBMCBlockTextRU = this.noxData.text_ru;
					this.noxTBMCBlockTextEN = this.noxData.text_en;
					this.noxTBMCBlockNextCId = this.$parent.noxTBMCCommandsOptions[this.$parent.noxTBMCCommandsOptions.findIndex(i => i.code == this.noxData.next_cid)];
					this.noxTBMCBlockRecipients = (this.noxData.details.recipients ? this.noxData.details.recipients.join(',') : '');
					this.noxTBMCBlockIsPreview = this.noxData.is_preview;
				}
				else if (this.noxType == 'editTBMCKeyboard') {

					this.noxData = this.$parent.noxTBMCKeyboards[this.noxTBMCCommandId][this.noxTBMCBlockId];
					this.noxTBMCKeyboardType = this.noxTBMCKeyboardTypes[this.noxData.type];
				}
				else if (this.noxType == 'editTBMCKeyboardCell') {

					this.noxData = this.$parent.noxTBMCKeyboards[this.noxTBMCCommandId][this.noxTBMCBlockId].data[this.noxTBMCKeyboardRowId][this.noxTBMCKeyboardCellId];
					this.noxTBMCKeyboardNextCId = this.$parent.noxTBMCCommandsOptions[this.$parent.noxTBMCCommandsOptions.findIndex(i => i.code == this.noxData.next_cid)];
					this.noxTBMCKeyboardCellNameRU = this.noxData.cell_name_ru;
					this.noxTBMCKeyboardCellNameEN = this.noxData.cell_name_en;
					this.noxTBMCKeyboardCellStatus = this.noxData.cell_status;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertTBARAnswerText = '';
				this.noxAlertTBARTemplateAId = '';
				this.noxAlertTBARTemplateText = '';
				this.noxAlertTBMCCommandName = '';
				this.noxAlertTBMCBlockType = '';
				this.noxAlertTBMCBlockTextRU = '';
				this.noxAlertTBMCBlockTextEN = '';
				this.noxAlertTBMCBlockNextCId = '';
				this.noxAlertTBMCBlockRecipients = '';
				this.noxAlertTBMCBlockIsPreview = '';
				this.noxAlertTBMCKeyboardType = '';
				this.noxAlertTBMCKeyboardNextCId = '';
				this.noxAlertTBMCKeyboardCellNameRU = '';
				this.noxAlertTBMCKeyboardCellNameEN = '';
				this.noxAlertTBMCKeyboardCellStatus = '';
			},
			getError1: function(i, data) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id ответа на вопрос.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id ответа на вопрос.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать текст ответа на вопрос.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный текст ответа на вопрос. Длина: до ' + data.max + ' символов.'; }
				else if (i == 5) { this.noxTemp = 'Данный ответ на вопрос используется в шаблоне #' + data.tid + '. Удаление невозможно.'; }
				else if (i == 6) { this.noxTemp = 'Id ответа на вопрос не найден в системе.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError2: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id шаблона вопроса.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id шаблона вопроса.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо выбрать ответ на вопрос.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо выбрать верный ответ на вопрос.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать шаблон вопроса.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный шаблон вопроса.'; }
				else if (i == 7) { this.noxTemp = 'Id шаблона вопроса не найден в системе.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError3: function(i, data) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id команды.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id команды.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать название команды.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верное название команды.'; }
				else if (i == 5) { this.noxTemp = 'Данная команда используется в других номерах команд: ' + data.cids + '. Удаление невозможно.'; }
				else if (i == 6) { this.noxTemp = 'Id команды не найден в системе.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError4: function(i, data) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id блока.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id блока.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо выбрать тип блока.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо выбрать верный тип блока.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать текст блока (ru).'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный текст блока (ru). Длина: до ' + data.max + ' символов.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать текст блока (en).'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный текст блока (en). Длина: до ' + data.max + ' символов.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать список получателей ответа.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный список получателей ответа.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать предпросмотр ссылок.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верный предпросмотр ссылок.'; }
				else if (i == 13) { this.noxTemp = 'Id блока не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError5: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id клавиатуры.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id клавиатуры.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать данные клавиатуры.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верные данные клавиатуры.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо выбрать тип клавиатуры.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо выбрать верный тип клавиатуры.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать id строки клавиатуры.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный id строки клавиатуры.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать id кнопки клавиатуры.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный id кнопки клавиатуры.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо выбрать вызываемую команду.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо выбрать верную вызываемую команду.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать название кнопки (ru).'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верное название кнопки (ru).'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать название кнопки (en).'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать верное название кнопки (en).'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать статус кнопки.'; }
				else if (i == 18) { this.noxTemp = 'Необходимо указать верный статус кнопки.'; }
				else if (i == 19) { this.noxTemp = 'Id клавиатуры не найден в системе.'; }
				else if (i == 20) { this.noxTemp = 'Id строки клавиатуры не найден в системе.'; }
				else if (i == 21) { this.noxTemp = 'Id кнопки клавиатуры не найден в системе.'; }
				else if (i == 22) { this.noxTemp = 'Id вызываемой команды не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addTBARAnswer', 'editTBARAnswer'].includes(_this.noxType)) {
						if (!_this.noxTBARAnswerText) {
							_this.noxAlertTBARAnswerText = _this.getError1(3);
						}
						else if (_this.noxTBARAnswerText.length > _this.noxTBTextLengths[0]) {
							_this.noxAlertTBARAnswerText = _this.getError1(4, { max: _this.noxTBTextLengths[0] });
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/alerts/tb/ar/answers';
							config.data = { id: Number(_this.noxTBARAnswerId), text: _this.noxTBARAnswerText };
							config.method = _this.noxType == 'addTBARAnswer' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteTBARAnswer') {
						config.url = '/v2/admin/account/alerts/tb/ar/answers';
						config.data = { id: Number(_this.noxTBARAnswerId) };
						config.method = 'delete';
					}
					else if (_this.noxType == 'deleteTBARQuestions') {
						config.url = '/v2/admin/account/alerts/tb/ar/questions/all';
						config.method = 'delete';
					}
					else if (['addTBARTemplate', 'editTBARTemplate'].includes(_this.noxType)) {
						if (!_this.noxTBARTemplateAId) {
							_this.noxAlertTBARTemplateAId = _this.getError2(3);
						}
						else if (_this.noxTBARTemplateAId.code == undefined) {
							_this.noxAlertTBARTemplateAId = _this.getError2(4);
						}
						if (!_this.noxTBARTemplateText) {
							_this.noxAlertTBARTemplateText = _this.getError2(5);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/alerts/tb/ar/templates';
							config.data = { id: Number(_this.noxTBARTemplateId), aid: Number(_this.noxTBARTemplateAId.code), text: _this.noxTBARTemplateText };
							config.method = _this.noxType == 'addTBARTemplate' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteTBARTemplate') {
						config.url = '/v2/admin/account/alerts/tb/ar/templates';
						config.data = { id: Number(_this.noxTBARTemplateId) };
						config.method = 'delete';
					}
					else if (['addTBMCCommand', 'editTBMCCommand'].includes(_this.noxType)) {
						if (!_this.noxTBMCCommandName) {
							_this.noxAlertTBMCCommandName = _this.getError3(3);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxTBMCCommandName)) {
							_this.noxAlertTBMCCommandName = _this.getError3(4);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/alerts/tb/mc/commands';
							config.data = { id: Number(_this.noxTBMCCommandId), name: _this.noxTBMCCommandName };
							config.method = _this.noxType == 'addTBMCCommand' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteTBMCCommand') {
						config.url = '/v2/admin/account/alerts/tb/mc/commands';
						config.data = { id: Number(_this.noxTBMCCommandId) };
						config.method = 'delete';
					}
					else if (['addTBMCBlock', 'editTBMCBlock'].includes(_this.noxType)) {
						if (!_this.noxTBMCBlockType) {
							_this.noxAlertTBMCBlockType = _this.getError4(3);
						}
						else if (_this.noxTBMCBlockType.code == undefined) {
							_this.noxAlertTBMCBlockType = _this.getError4(4);
						}
						else if ([0,2,3].includes(_this.noxTBMCBlockType.code)) {
							if (_this.noxTBMCBlockType.code == 3) {
								if (!_this.noxTBMCBlockNextCId) {
									_this.noxAlertTBMCBlockNextCId = _this.getError5(11);
								}
								else if (_this.noxTBMCBlockNextCId.code == undefined) {
									_this.noxAlertTBMCBlockNextCId = _this.getError5(12);
								}
								if (!_this.noxTBMCBlockRecipients) {
									_this.noxAlertTBMCBlockRecipients = _this.getError4(9);
								}
								else {
									var recipients = String(_this.noxTBMCBlockRecipients).split(',');
									if (recipients.length) {
										for (var r in recipients) {
											if ((recipients[r] ^ 0) !== Number(recipients[r]) || Number(recipients[r]) < 1) {
												_this.noxAlertTBMCBlockRecipients = _this.getError4(10); break;
											}
										}
									}
									else {
										_this.noxAlertTBMCBlockRecipients = _this.getError4(10);
									}
								}
							}
							if (!_this.noxTBMCBlockTextRU) {
								_this.noxAlertTBMCBlockTextRU = _this.getError4(5);
							}
							if (!_this.noxTBMCBlockTextEN) {
								_this.noxAlertTBMCBlockTextEN = _this.getError4(7);
							}
						}
						if (_this.noxTBMCBlockTextRU || _this.noxTBMCBlockTextEN) {
							if (_this.noxTBMCBlockTextRU && _this.noxTBMCBlockTextRU.length > _this.noxTBTextLengths[Number(_this.noxTBMCBlockType.code)]) {
								_this.noxAlertTBMCBlockTextRU = _this.getError4(6, { max: _this.noxTBTextLengths[Number(_this.noxTBMCBlockType.code)] });
							}
							if (_this.noxTBMCBlockTextEN && _this.noxTBMCBlockTextEN.length > _this.noxTBTextLengths[Number(_this.noxTBMCBlockType.code)]) {
								_this.noxAlertTBMCBlockTextEN = _this.getError4(8, { max: _this.noxTBTextLengths[Number(_this.noxTBMCBlockType.code)] });
							}
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/alerts/tb/mc/blocks';
							config.data = { id: Number(_this.noxTBMCBlockId), cid: Number(_this.noxTBMCCommandId), type: Number(_this.noxTBMCBlockType.code), text_ru: _this.noxTBMCBlockTextRU, text_en: _this.noxTBMCBlockTextEN, next_cid: (_this.noxTBMCBlockType.code == 3 ? Number(_this.noxTBMCBlockNextCId.code) : 0), recipients: _this.noxTBMCBlockRecipients, is_preview: Number(_this.noxTBMCBlockIsPreview) };
							config.method = _this.noxType == 'addTBMCBlock' ? 'post' : 'patch';
						}
					}
					else if (['swapUpTBMCBlock', 'swapDownTBMCBlock'].includes(_this.noxType)) {
						config.url = '/v2/admin/account/alerts/tb/mc/blocks/swap/' + (_this.noxType == 'swapUpTBMCBlock' ? 'up' : 'down');
						config.data = { id: Number(_this.noxTBMCBlockId), cid: Number(_this.noxTBMCCommandId) };
						config.method = 'patch';
					}
					else if (_this.noxType == 'deleteTBMCBlock') {
						config.url = '/v2/admin/account/alerts/tb/mc/blocks';
						config.data = { id: Number(_this.noxTBMCBlockId) };
						config.method = 'delete';
					}
					else if (['addTBMCKeyboard', 'editTBMCKeyboard'].includes(_this.noxType)) {
						if (!_this.noxTBMCKeyboardType) {
							_this.noxAlertTBMCKeyboardType = _this.getError5(5);
						}
						else if (_this.noxTBMCKeyboardType.code == undefined) {
							_this.noxAlertTBMCKeyboardType = _this.getError5(6);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/alerts/tb/mc/keyboards';
							config.data = { id: Number(_this.noxTBMCKeyboardId), bid: Number(_this.noxTBMCBlockId), cid: Number(_this.noxTBMCCommandId), type: Number(_this.noxTBMCKeyboardType.code) };
							config.method = _this.noxType == 'addTBMCKeyboard' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'saveTBMCKeyboard') {
						var data = [];
						if (_this.$parent.noxTBMCKeyboards[_this.noxTBMCCommandId] !== undefined) {
							if (_this.$parent.noxTBMCKeyboards[_this.noxTBMCCommandId][_this.noxTBMCBlockId] !== undefined) {
								var rows = _this.$parent.noxTBMCKeyboards[_this.noxTBMCCommandId][_this.noxTBMCBlockId].data;
								if (rows.length) {
									for (var row in rows) {
										var cells = rows[row], cell_data = [];
										if (cells.length) {
											for (var cell in cells) {
												if (cells[cell].next_cid && cells[cell].cell_name_ru && cells[cell].cell_name_en) {
													cell_data.push([cells[cell].next_cid, cells[cell].cell_name_ru, cells[cell].cell_name_en, cells[cell].cell_status]);
												}
											}
											if (cell_data.length) { data.push(cell_data); }
										}
									}
								}
							}
						}
						if (!data) { _this.noxAlert = _this.getError5(3); }
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/alerts/tb/mc/keyboards/resave';
							config.data = { id: Number(_this.noxTBMCKeyboardId), data: data };
							config.method = 'post';
						}
					}
					else if (_this.noxType == 'addTBMCKeyboardRow') {
						config.url = '/v2/admin/account/alerts/tb/mc/keyboards/row';
						config.data = { id: Number(_this.noxTBMCKeyboardId), bid: Number(_this.noxTBMCBlockId), cid: Number(_this.noxTBMCCommandId) };
						config.method = 'post';
					}
					else if (_this.noxType == 'deleteTBMCKeyboardRow') {
						config.url = '/v2/admin/account/alerts/tb/mc/keyboards/row';
						config.data = { id: Number(_this.noxTBMCKeyboardId), row_id: Number(_this.noxTBMCKeyboardRowId) };
						config.method = 'delete';
					}
					else if (['addTBMCKeyboardCell', 'editTBMCKeyboardCell'].includes(_this.noxType)) {
						if (!_this.noxTBMCKeyboardNextCId) {
							_this.noxAlertTBMCKeyboardNextCId = _this.getError5(11);
						}
						else if (_this.noxTBMCKeyboardNextCId.code == undefined) {
							_this.noxAlertTBMCKeyboardNextCId = _this.getError5(12);
						}
						if (!_this.noxTBMCKeyboardCellNameRU) {
							_this.noxAlertTBMCKeyboardCellNameRU = _this.getError5(13);
						}
						if (!_this.noxTBMCKeyboardCellNameEN) {
							_this.noxAlertTBMCKeyboardCellNameEN = _this.getError5(15);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/alerts/tb/mc/keyboards/cell';
							config.data = { id: Number(_this.noxTBMCKeyboardId), bid: Number(_this.noxTBMCBlockId), cid: Number(_this.noxTBMCCommandId), row_id: Number(_this.noxTBMCKeyboardRowId), cell_id: Number(_this.noxTBMCKeyboardCellId), next_cid: Number(_this.noxTBMCKeyboardNextCId.code), cell_name_ru: _this.noxTBMCKeyboardCellNameRU, cell_name_en: _this.noxTBMCKeyboardCellNameEN, cell_status: Number(_this.noxTBMCKeyboardCellStatus) };
							config.method = _this.noxType == 'addTBMCKeyboardCell' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteTBMCKeyboardCell') {
						config.url = '/v2/admin/account/alerts/tb/mc/keyboards/cell';
						config.data = { id: Number(_this.noxTBMCKeyboardId), row_id: Number(_this.noxTBMCKeyboardRowId), cell_id: Number(_this.noxTBMCKeyboardCellId) };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['addTBARAnswer', 'editTBARAnswer', 'deleteTBARAnswer', 'deleteTBARQuestions', 'addTBARTemplate', 'editTBARTemplate', 'deleteTBARTemplate', 'addTBMCCommand', 'editTBMCCommand', 'deleteTBMCCommand', 'addTBMCBlock', 'editTBMCBlock', 'swapUpTBMCBlock', 'swapDownTBMCBlock', 'deleteTBMCBlock', 'addTBMCKeyboard', 'editTBMCKeyboard', 'saveTBMCKeyboard', 'addTBMCKeyboardRow', 'deleteTBMCKeyboardRow', 'addTBMCKeyboardCell', 'editTBMCKeyboardCell', 'deleteTBMCKeyboardCell'].includes(_this.noxType)) {
									_this.$parent.getTBData(data.data);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addTBARAnswer', 'editTBARAnswer', 'deleteTBARAnswer'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError1(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError1(2); }
										else if (data.response.data.error == 'TEXT_IS_EMPTY') { _this.noxAlertTBARAnswerText = _this.getError1(3); }
										else if (data.response.data.error == 'TEXT_NOT_VALID') { _this.noxAlertTBARAnswerText = _this.getError1(4, { max: data.response.data.max }); }
									}
									else if (['addTBARTemplate', 'editTBARTemplate', 'deleteTBARTemplate'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError2(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError2(2); }
										else if (data.response.data.error == 'AID_IS_EMPTY') { _this.noxAlertTBARTemplateAId = _this.getError2(3); }
										else if (data.response.data.error == 'AID_NOT_VALID') { _this.noxAlertTBARTemplateAId = _this.getError2(4); }
										else if (data.response.data.error == 'TEXT_IS_EMPTY') { _this.noxAlertTBARTemplateText = _this.getError2(5); }
										else if (data.response.data.error == 'TEXT_NOT_VALID') { _this.noxAlertTBARTemplateText = _this.getError2(6); }
									}
									else if (['addTBMCCommand', 'editTBMCCommand', 'deleteTBMCCommand'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError3(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError3(2); }
										else if (data.response.data.error == 'NAME_IS_EMPTY') { _this.noxAlertTBMCCommandName = _this.getError3(3); }
										else if (data.response.data.error == 'NAME_NOT_VALID') { _this.noxAlertTBMCCommandName = _this.getError3(4); }
									}
									else if (['addTBMCBlock', 'editTBMCBlock', 'swapUpTBMCBlock', 'swapDownTBMCBlock', 'deleteTBMCBlock'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError4(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError4(2); }
										else if (data.response.data.error == 'CID_IS_EMPTY') { _this.noxAlert = _this.getError3(1); }
										else if (data.response.data.error == 'CID_NOT_VALID') { _this.noxAlert = _this.getError3(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertTBMCBlockType = _this.getError4(3); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertTBMCBlockType = _this.getError4(4); }
										else if (data.response.data.error == 'TEXT_RU_IS_EMPTY') { _this.noxAlertTBMCBlockTextRU = _this.getError4(5); }
										else if (data.response.data.error == 'TEXT_RU_NOT_VALID') { _this.noxAlertTBMCBlockTextRU = _this.getError4(6, { max: data.response.data.max }); }
										else if (data.response.data.error == 'TEXT_EN_IS_EMPTY') { _this.noxAlertTBMCBlockTextEN = _this.getError4(7); }
										else if (data.response.data.error == 'TEXT_EN_NOT_VALID') { _this.noxAlertTBMCBlockTextEN = _this.getError4(8, { max: data.response.data.max }); }
										else if (data.response.data.error == 'NEXT_CID_IS_EMPTY') { _this.noxAlertTBMCBlockNextCId = _this.getError5(11); }
										else if (data.response.data.error == 'NEXT_CID_NOT_VALID') { _this.noxAlertTBMCBlockNextCId = _this.getError5(12); }
										else if (data.response.data.error == 'RECIPIENTS_IS_EMPTY') { _this.noxAlertTBMCBlockRecipients = _this.getError4(9); }
										else if (data.response.data.error == 'RECIPIENTS_NOT_VALID') { _this.noxAlertTBMCBlockRecipients = _this.getError4(10); }
										else if (data.response.data.error == 'IS_PREVIEW_IS_EMPTY') { _this.noxAlertTBMCBlockIsPreview = _this.getError4(11); }
										else if (data.response.data.error == 'IS_PREVIEW_NOT_VALID') { _this.noxAlertTBMCBlockIsPreview = _this.getError4(12); }
									}
									else if (['addTBMCKeyboard', 'editTBMCKeyboard', 'saveTBMCKeyboard', 'addTBMCKeyboardRow', 'deleteTBMCKeyboardRow', 'addTBMCKeyboardCell', 'editTBMCKeyboardCell', 'deleteTBMCKeyboardCell'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError5(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError5(2); }
										else if (data.response.data.error == 'DATA_IS_EMPTY') { _this.noxAlert = _this.getError5(3); }
										else if (data.response.data.error == 'DATA_NOT_VALID') { _this.noxAlert = _this.getError5(4); }
										else if (data.response.data.error == 'BID_IS_EMPTY') { _this.noxAlert = _this.getError4(1); }
										else if (data.response.data.error == 'BID_NOT_VALID') { _this.noxAlert = _this.getError4(2); }
										else if (data.response.data.error == 'CID_IS_EMPTY') { _this.noxAlert = _this.getError3(1); }
										else if (data.response.data.error == 'CID_NOT_VALID') { _this.noxAlert = _this.getError3(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertTBMCKeyboardType = _this.getError5(5); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertTBMCKeyboardType = _this.getError5(6); }
										else if (data.response.data.error == 'ROW_ID_IS_EMPTY') { _this.noxAlert = _this.getError5(7); }
										else if (data.response.data.error == 'ROW_ID_NOT_VALID') { _this.noxAlert = _this.getError5(8); }
										else if (data.response.data.error == 'CELL_ID_IS_EMPTY') { _this.noxAlert = _this.getError5(9); }
										else if (data.response.data.error == 'CELL_ID_NOT_VALID') { _this.noxAlert = _this.getError5(10); }
										else if (data.response.data.error == 'NEXT_CID_IS_EMPTY') { _this.noxAlertTBMCKeyboardNextCId = _this.getError5(11); }
										else if (data.response.data.error == 'NEXT_CID_NOT_VALID') { _this.noxAlertTBMCKeyboardNextCId = _this.getError5(12); }
										else if (data.response.data.error == 'CELL_NAME_RU_IS_EMPTY') { _this.noxAlertTBMCKeyboardCellNameRU = _this.getError5(13); }
										else if (data.response.data.error == 'CELL_NAME_RU_NOT_VALID') { _this.noxAlertTBMCKeyboardCellNameRU = _this.getError5(14); }
										else if (data.response.data.error == 'CELL_NAME_EN_IS_EMPTY') { _this.noxAlertTBMCKeyboardCellNameEN = _this.getError5(15); }
										else if (data.response.data.error == 'CELL_NAME_EN_NOT_VALID') { _this.noxAlertTBMCKeyboardCellNameEN = _this.getError5(16); }
										else if (data.response.data.error == 'CELL_STATUS_IS_EMPTY') { _this.noxAlertTBMCKeyboardCellStatus = _this.getError5(17); }
										else if (data.response.data.error == 'CELL_STATUS_NOT_VALID') { _this.noxAlertTBMCKeyboardCellStatus = _this.getError5(18); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['addTBARAnswer', 'editTBARAnswer', 'deleteTBARAnswer'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError1(6); }
									}
									else if (['addTBARTemplate', 'editTBARTemplate', 'deleteTBARTemplate'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError2(7); }
										else if (data.response.data.error == 'AID_NOT_FOUND') { _this.noxAlertTBARTemplateAId = _this.getError1(6); }
									}
									else if (['addTBMCCommand', 'editTBMCCommand', 'deleteTBMCCommand'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError3(6); }
									}
									else if (['addTBMCBlock', 'editTBMCBlock', 'swapUpTBMCBlock', 'swapDownTBMCBlock', 'deleteTBMCBlock'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError4(13); }
										else if (data.response.data.error == 'CID_NOT_FOUND') { _this.noxAlert = _this.getError3(6); }
										else if (data.response.data.error == 'NEXT_CID_NOT_FOUND') { _this.noxAlertTBMCBlockNextCId = _this.getError5(22); }
									}
									else if (['addTBMCKeyboard', 'editTBMCKeyboard', 'saveTBMCKeyboard', 'addTBMCKeyboardRow', 'addTBMCKeyboardCell', 'editTBMCKeyboardCell', 'deleteTBMCKeyboardCell'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError5(19); }
										else if (data.response.data.error == 'BID_NOT_FOUND') { _this.noxAlert = _this.getError4(13); }
										else if (data.response.data.error == 'CID_NOT_FOUND') { _this.noxAlert = _this.getError3(6); }
										else if (data.response.data.error == 'ROW_ID_NOT_FOUND') { _this.noxAlert = _this.getError5(20); }
										else if (data.response.data.error == 'CELL_ID_NOT_FOUND') { _this.noxAlert = _this.getError5(21); }
										else if (data.response.data.error == 'NEXT_CID_NOT_FOUND') { _this.noxAlertTBMCKeyboardNextCId = _this.getError5(22); }
									}
								}
								else if (data.response.status == 409) {
									if (_this.noxType == 'deleteTBARAnswer') {
										if (data.response.data.error == 'ANSWER_IS_USED') { _this.noxAlert = _this.getError1(5, { tid: data.response.data.tid }); }
									}
									else if (_this.noxType == 'deleteTBMCCommand') {
										if (data.response.data.error == 'COMMAND_IS_USED') { _this.noxAlert = _this.getError3(5, { cids: data.response.data.cids }); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError1(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(type, event) {
				if      (type == 1) { this.noxTBMCKeyboardCellStatus = Number(event.value); }
				else if (type == 2) { this.noxTBMCBlockIsPreview = Number(event.value); }
			},
			close: function() {
				this.$modal.hide('NoxModalTelegramBot');
			}
		}
	}
</script>
